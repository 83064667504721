import { MandatoryMoreInfoModal } from "@/interfaces/customer";
import { store } from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./CheckInAddModal.template.vue";

@Component({
  mixins: [Template]
})
export default class CheckInAddModalComponent extends Vue {
  @Prop({ required: true })
  public modalData!: MandatoryMoreInfoModal;
  @Prop({ default: null })
  public item!: any;

  public accept() {
    store.commit("CustomerModule/setNavigateToCheckIn", true);
    store.dispatch("CustomerModule/savedForCheckin", true);
    this.$emit("resolve");
    this.$router.push({
      name: "customers-edit",
      params: {
        id: this.item.id,
        currentModel: this.item
      },
      query: {
        customerDetailsIncomplete: "true"
      }
    });
  }

  public cancel() {
    this.$emit("reject");
  }
}
