import { policyList } from "@/enums/permissions";
import { Customer } from "@/interfaces/customer";
import { EventBus, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import {
  ActionSubheader,
  TableAction,
  TableItem,
  TableSuccessModalResponse
} from "helix-vue-components";

import CheckInAddModalComponent from "@/components/retail/customers/customersList/check-in/CheckInAddModal.component";
import Vue from "vue";
import { customerService } from "./customer.service";
import { messagesService } from "./messages.service";

class CustomerActionService extends Vue {
  public getGeneralActions(
    addCustomer: () => void,
    filtersCallback: () => void,
    openScanner: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        id: "btn_add_customer",
        action: addCustomer
      },
      {
        icon: "fal fa-search",
        id: "btn_search_customer",
        otherComponent: {
          name: "TableSearchComponent",
          action: customerService.searchEvent()
        }
      },
      {
        icon: "fal fa-barcode-read",
        action: openScanner
      },
      {
        icon: "fal fa-filter",
        action: filtersCallback
      }
    ];
  }

  /**
   * @override
   */
  public getCheckInRowActions(createModifyFlag: boolean): TableAction[] {
    return [
      {
        icon: createModifyFlag ? "fal fa-pen" : "fal fa-eye",
        id: "btn_edit_customer",
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: createModifyFlag ? "customers-edit" : "customers-view",
            params: {
              id: arg[0].customer_id,
              currentModel: arg[0]
            }
          });
        }
      },
      {
        icon: "fal fa-times",
        id: "btn_delete_customer",
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("customer.remove_cutomer").toString(),
          modalSuccessText: "yes",
          modalIdSuccess: "btn_delete_yes",
          modalSuccessAction: async (arg: TableSuccessModalResponse) => {
            try {
              await customerService.checkOutFromCheckIn(
                (arg.item as Customer).customer_id!
              );
              store.dispatch("CustomerModule/loadCustomers");
              arg.unselectModal();
            } catch (e) {
              messagesService.renderErrorMessage(e);
            }
          },
          modalCancelText: "no",
          modalIdCancel: "btn_delete_no"
        }
      }
    ];
  }

  public getCustomerRowActions(
    checkInCustomer: (arg: TableSuccessModalResponse) => void,
    createModifyFlag: boolean
  ): any[] {
    return [
      {
        icon: "fal fa-exclamation-triangle",
        id: "btb_customer_detail_incomplete",
        class: "customer_detail_incomplete",
        visibleCondition: (arg: any) => {
          return (
            store.getters["AuthModule/currentRetailSettings"]
              .pharmacy_mode_enabled && !arg.item.more_info_filled
          );
        }
      },
      {
        icon: createModifyFlag ? "fal fa-pen" : "fal fa-eye",
        id: "btn_edit_customer",
        action: (...arg: Customer[]) => {
          if (createModifyFlag) {
            store.dispatch(
              "RouterModule/go",
              store.getters["AuthModule/currentRetailSettings"]
                .pharmacy_mode_enabled && !arg[0].more_info_filled
                ? {
                    name: "customers-edit",
                    params: {
                      id: arg[0].customer_id,
                      currentModel: arg[0]
                    },
                    query: {
                      customerDetailsIncomplete: true
                    }
                  }
                : {
                    name: "customers-edit",
                    params: {
                      id: arg[0].customer_id,
                      currentModel: arg[0]
                    }
                  }
            );
          } else {
            store.dispatch("RouterModule/go", {
              name: "customers-view",
              params: {
                id: arg[0].customer_id,
                currentModel: arg[0]
              }
            });
          }
        }
      },
      {
        icon: "add",
        id: "btn_checkin",
        visibleCondition: (arg: any) => {
          if (!createModifyFlag) {
            return arg.item.more_info_filled;
          }
          return true;
        },
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("customer.add_customer_list").toString(),
          modalSuccessText: "yes",
          modalIdSuccess: "btn_checkin_yes",
          modalSuccessAction: async (arg: TableSuccessModalResponse) => {
            if (
              store.getters["AuthModule/currentRetailSettings"]
                .pharmacy_mode_enabled &&
              !arg.item.more_info_filled
            ) {
              this.$modals
                .load(
                  CheckInAddModalComponent,
                  {
                    size: "fit"
                  },
                  {
                    modalData: {
                      prompt: i18n
                        .t("customer_more_info_missing.prompt")
                        .toString(),
                      question: i18n
                        .t("customer_more_info_missing.question")
                        .toString(),
                      action: i18n
                        .t("customer_more_info_missing.action")
                        .toString(),
                      showResolveBtn: true,
                      showRejectBtn: true,
                      resolveBtnText: i18n.t("yes").toString(),
                      rejectBtnText: i18n.t("no").toString()
                    },
                    item: arg.item
                  }
                )
                .then(
                  () => {
                    arg.unselectModal();
                  },
                  () => {
                    arg.unselectModal();
                  }
                );
            } else {
              const customerItem = arg.item as Customer;
              store.dispatch("CustomerModule/setCustomer", customerItem);
              const customerValidAdult = customerService.customerValidAdult(
                customerItem
              );
              if (customerValidAdult.notAdult) {
                if (customerValidAdult.action === "warning") {
                  await customerService
                    .infoModalValidate(
                      `${i18n.tc("under_age_customer_action")} ${
                        customerValidAdult.age
                      }. ${i18n.tc("under_age_customer_question")}`
                    )
                    .then(() => {
                      checkInCustomer(arg);
                    });
                } else {
                  EventBus.$emit("modal", {
                    msgModal: {
                      title: "under_age_customer_title",
                      body: `${i18n.tc("under_age_customer_action")} ${
                        customerValidAdult.age
                      }`,
                      captionButton: "OK"
                    }
                  });
                }
              } else {
                const route = await store.dispatch("RouterModule/current");

                if (route.name === "pos-customers-list") {
                  checkInCustomer(arg);
                } else {
                  store.dispatch(
                    "CustomerModule/checkInCustomer",
                    customerItem.customer_id
                  );
                }
              }
            }
          },
          modalCancelText: "no",
          modalIdCancel: "btn_checkin_no"
        }
      }
    ];
  }

  public getCustomerManagementActions(
    viewFlag: boolean,
    editFlag: boolean
  ): TableAction[] {
    return [
      {
        icon: "fal fa-exclamation-triangle",
        id: "btb_customer_detail_incomplete",
        class: "customer_detail_incomplete",
        visibleCondition: arg => {
          return (
            store.getters["AuthModule/currentRetailSettings"]
              .pharmacy_mode_enabled && !arg.item.more_info_filled
          );
        }
      },
      {
        icon: editFlag ? "fal fa-pen" : "fas fa-eye",
        id: "btn_view_customer",
        visibleCondition: () => viewFlag,
        action: (...arg: any[]) => {
          if (editFlag) {
            store.dispatch(
              "RouterModule/go",
              store.getters["AuthModule/currentRetailSettings"]
                .pharmacy_mode_enabled && !arg[0].more_info_filled
                ? {
                    name: "customers-edit",
                    params: {
                      id: arg[0].customer_id,
                      currentModel: arg[0]
                    },
                    query: {
                      customerDetailsIncomplete: true
                    }
                  }
                : {
                    name: "customers-edit",
                    params: {
                      id: arg[0].customer_id,
                      currentModel: arg[0]
                    }
                  }
            );
          } else {
            store.dispatch("RouterModule/go", {
              name: "customers-view",
              params: {
                id: arg[0].customer_id,
                currentModel: arg[0]
              }
            });
          }
        }
      },
      {
        icon: "",
        switch: "status.is_active",
        id: "btn_delete_customer",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.deleteCustomer
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("customer.remove_cutomer").toString(),
          modalSuccessText: "yes",
          modalIdSuccess: "btn_delete_yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            customerService
              .toggleCustomer(arg.item as Customer, arg.modelValue)
              .then(() => {
                store.dispatch("CustomerModule/loadCustomers");
              })
              .finally(arg.unselectModal);
          },
          modalCancelText: "no",
          modalIdCancel: "btn_delete_no"
        }
      }
    ];
  }

  public managementAction(
    csvModal: () => void,
    customerSearchAction: (value: string) => void,
    toggleFilterActive: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-file-csv",
        action: csvModal
      },
      {
        icon: "fal fa-plus",
        id: "btn_create_customer",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createCustomers
          ),
        action: () => {
          store.dispatch("RouterModule/go", {
            name: "customers-add"
          });
        }
      },
      {
        icon: "fal fa-search",
        id: "btn_search_customer",
        otherComponent: {
          name: "TableSearchComponent",
          action: customerSearchAction
        }
      },
      {
        icon: "fal fa-filter",
        action: toggleFilterActive,
        id: "btn_filter_prod"
      }
    ];
  }

  public getCustomersListAction(): ActionSubheader[] {
    return [
      {
        icon: "fal fa-search",
        id: "btn_customer_search",
        otherComponent: {
          name: "TableSearchComponent"
        }
      }
    ];
  }
}

export const customerActionService = new CustomerActionService();
